//変数設定の読み込み
@import "../common/_variable.scss";

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// トップ
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//------------------------------//
// 商品リンク
//------------------------------//
  .search {
    @include mb(x6);
    .search-headline {
      margin-bottom: 16px;
      font-size: $fs-xxl;
      font-weight: bold;
      em {
        color: $red;
        font-size: $fs-4xl;
      }
      font-family: $ff-min;
    }
    .cate-list {
      @include flex();
      gap: 16px;
      &__item {
        width: calc(20% - (16px * 4 / 5));
        overflow: hidden;
        border-radius: 6px;
        background: $blu;
        a {
          .cate-vsl {
            overflow: hidden;
            img {
              transition: 0.3s ease-out;
            }
          }
          .cate-dtl {
            position: relative;
            @include flex(center,center,column);
            padding: 8px 12px;
            color: $wht;
            font-size: $fs-xs;
            &::before {
              content: '';
              @include pos($r:8px,$b:8px);
              width: 8px;
              height: 8px;
              background: linear-gradient( to bottom right, $tra 50%, $wht 50%);
            }
            em {
              display: block;
              font-size: $fs-m;
              line-height: 1.2;
            }
          }
          &:hover {
            .cate-vsl {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
  }

//------------------------------//
// 年齢に抗わず、自分に合った美しさを
//------------------------------//
  .concept {
    position: relative;
    max-width: 720px;
    &::before {
      content: '';
      @include pos($t:-24px,$l:calc(100% + 40px));
      width: 460px;
      height: 400px;
      background: printUrl('/img/top/concept-bg.svg') center / contain no-repeat;
    }
    .concept-headline {
      margin-bottom: 16px;
      font-size: $fs-xxl;
      font-weight: bold;
      em {
        color: $red;
        font-size: $fs-3xl;
      }
      font-family: $ff-min;
    }
  }