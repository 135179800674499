@charset "utf-8";
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// 各種変数の設定
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//------------------------------//
// ハックの設定
//------------------------------//
  @mixin ff {
    @-moz-document url-prefix() {
      @content;
    }
  }
  @mixin safari {
    @at-root _::-webkit-full-page-media, _:future, :root & {
      @content;
    }
  }

//------------------------------//
// ブレイクポイントの設定
//------------------------------//
  $xl: 1280px;
  $l:  1024px;
  $m:   960px;
  $s:   768px;
  $xs:  520px;
  @mixin break($breakpoint: m) {
    @if $breakpoint == xl {
      @media screen and (max-width: $xl) { @content; }
    } @else if $breakpoint == l {
      @media screen and (max-width: $l) { @content; }
    } @else if $breakpoint == m {
      @media screen and (max-width: $m) { @content; }
    } @else if $breakpoint == s {
      @media screen and (max-width: $s) { @content; }
    } @else if $breakpoint == xs {
      @media screen and (max-width: $xs) { @content; }
    }
  }

//------------------------------//
// フォントの設定
//------------------------------//
  // フォントファミリー
  $ff-base: 'Noto Sans JP', sans-serif; // ベース
  $ff-en: 'Inter', sans-serif;                   // 英語
  $ff-min: 'Noto Serif JP', serif;                            // 明朝
  // フォントサイズ
  $fs-xxs: 1.0rem;
  $fs-xs:  1.2rem;
  $fs-s:   1.4rem;
  $fs-m:   1.6rem;
  $fs-l:   2.0rem;
  $fs-xl:  2.4rem;
  $fs-xxl: 2.8rem;
  $fs-3xl: 3.2rem;
  $fs-4xl: 4.0rem;

//------------------------------//
// 色の設定（使用しない色は削除）
//------------------------------//
  $tra  : transparent; // 透明
  $red  : #c80952;   // 赤色
  $blu  : #30669B;   // 青色
  $grn  : #73B41B;   // 黄緑色
  $d-grn: #42A33E;   // 緑色
  $beg  : #f4e9c5;   // ベージュ
  $brn  : #a52a2a;   // 茶色
  $pnk  : #ffc0cb;   // ピンク
  $ppl  : #c54ea0;   // 赤紫
  $vio  : #704cbc;   // 青紫
  $blk  : #333333;   // 黒色
  $wht  : #ffffff;   // 白色
  $l-gry: #eeeeee;   // 薄いグレー
  $gry  : #cccccc;   // グレー
  $d-gry: #999999;   // 濃いグレー
  $colors : (
    'red'  : $red,
    'blu'  : $blu,
    'grn'  : $grn,
    'beg'  : $beg,
    'brn'  : $brn,
    'pnk'  : $pnk,
    'ppl'  : $ppl,
    'vio'  : $vio,
    'blk'  : $blk,
    'wht'  : $wht,
    'l-gry': $l-gry,
    'gry'  : $gry,
    'd-gry': $d-gry
  );

//------------------------------//
// 画像パスの設定
//------------------------------//
  $img-path: "../../";
  @function printUrl($file-name) {
    @return url($img-path + $file-name);
  }

//------------------------------//
// 余白の設定
//------------------------------//
  // デフォルトの値 ※計算用の変数のため使用禁止
  $space: 8px;

  // margin、paddingのmixin
  @mixin mr($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    margin-right: calc(#{$space} * #{$set-size});
    @include break(m) {
      margin-right: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      margin-right: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin mb($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    margin-bottom: calc(#{$space} * #{$set-size});
    @include break(m) {
      margin-bottom: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      margin-bottom: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin ml($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    margin-left: calc(#{$space} * #{$set-size});
    @include break(m) {
      margin-left: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      margin-left: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin p($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pt($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-top: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-top: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-top: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pr($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-right: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-right: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-right: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pb($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-bottom: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-bottom: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-bottom: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin pl($i: null) {
    $set-size: 1;
    @if $i != null {
      $set-size: str-slice($i, 2);
    }
    padding-left: calc(#{$space} * #{$set-size});
    @include break(m) {
      padding-left: calc((#{$space} * #{$set-size}) * 0.75);
    }
    @include break(xs) {
      padding-left: calc((#{$space} * #{$set-size}) * 0.5);
    }
  }
  @mixin gap($i-ver: null,$i-hor: null) {
    $set-size-ver: 1;
    $set-size-hor: null;
    @if $i-ver != null {
      $set-size-ver: str-slice($i-ver, 2);
    }
    @if $i-hor != null {
      $set-size-hor: str-slice($i-hor, 2);
    }
    @if $i-hor != null {
      gap: calc(#{$space} * #{$set-size-ver}) calc(#{$space} * #{$set-size-hor});
    } @else {
      gap: calc(#{$space} * #{$set-size-ver});
    }
    @include break(m) {
      @if $i-hor != null {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.75) calc((#{$space} * #{$set-size-hor}) * 0.75);
      } @else {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.75);
      }
    }
    @include break(xs) {
      @if $i-hor != null {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.5) calc((#{$space} * #{$set-size-hor}) * 0.5);
      } @else {
        gap: calc((#{$space} * #{$set-size-ver}) * 0.5);
      }
    }
  }

//------------------------------//
// wrapの設定
//------------------------------//
  @mixin wrap($max: null) {
    width: 100%;
    @if $max != null {
      max-width: $max + px;
    }
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;
    @include break(xl) {
      padding-right: 32px;
      padding-left: 32px;
    }
    @include break(m) {
      padding-right: 24px;
      padding-left: 24px;
    }
    @include break(xs) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

//------------------------------//
// flexの設定
//------------------------------//
  @mixin flex($j: null, $a: null, $dir: null, $wrap: wrap) {
    display: flex;
    @if $wrap != null {
      flex-wrap: $wrap;
    }
    @if $j != null and $j != '' {
      @if $j == left {
        justify-content: flex-start;
      } @else if $j == right {
        justify-content: flex-end;
      } @else {
        justify-content: $j;
      }
    }
    @if $a != null and $a != ''  {
      @if $a == top {
        align-items: flex-start;
      } @else if $a == bottom {
        align-items: flex-end;
      } @else {
        align-items: $a;
      }
    }
    @if $dir != null and $dir != '' {
      flex-direction : $dir;
    }
  }

//------------------------------//
// ポジションの設定
//------------------------------//
  @mixin pos($p: null,$t: null, $r: null, $b: null, $l: null, $z: null) {
    position: absolute;
    @if $p != null and $p != '' {
      position: $p;
    }
    @if $t != null and $t != '' {
      top: $t;
    }
    @if $r != null and $r != '' {
      right: $r;
    }
    @if $b != null and $b != '' {
      bottom: $b;
    }
    @if $l != null and $l != '' {
      left: $l;
    }
    @if $z != null {
      z-index: $z;
    }
  }

//------------------------------//
// 角丸の設定
//------------------------------//
  @mixin round($i: null) {
    $set-size: 8px;
    @if $i != null {
      $set-size: $i;
    }
    border-radius: #{$set-size};
    @include break(m) {
      border-radius: calc(#{$set-size} * 0.75);
    }
    @include break(xs) {
      border-radius: calc(#{$set-size} * 0.5);
    }
  }

//------------------------------//
// 文字数制限の設定
//------------------------------//
  @mixin abbr($row: 1) {
    overflow: hidden;
    @if $row == 1 {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    } @else {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: $row;
    }
  }