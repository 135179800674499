.search {
  margin-bottom: calc(8px * 6);
}

@media screen and (max-width: 960px) {
  .search {
    margin-bottom: calc((8px * 6) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  .search {
    margin-bottom: calc((8px * 6) * 0.5);
  }
}

.search .search-headline {
  margin-bottom: 16px;
  font-size: 2.8rem;
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
}

.search .search-headline em {
  color: #c80952;
  font-size: 4rem;
}

.search .cate-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.search .cate-list__item {
  width: calc(20% - (16px * 4 / 5));
  overflow: hidden;
  border-radius: 6px;
  background: #30669B;
}

.search .cate-list__item a .cate-vsl {
  overflow: hidden;
}

.search .cate-list__item a .cate-vsl img {
  transition: 0.3s ease-out;
}

.search .cate-list__item a .cate-dtl {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 12px;
  color: #ffffff;
  font-size: 1.2rem;
}

.search .cate-list__item a .cate-dtl::before {
  content: '';
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 8px;
  height: 8px;
  background: linear-gradient(to bottom right, transparent 50%, #ffffff 50%);
}

.search .cate-list__item a .cate-dtl em {
  display: block;
  font-size: 1.6rem;
  line-height: 1.2;
}

.search .cate-list__item a:hover .cate-vsl img {
  transform: scale(1.1);
}

.concept {
  position: relative;
  max-width: 720px;
}

.concept::before {
  content: '';
  position: absolute;
  top: -24px;
  left: calc(100% + 40px);
  width: 460px;
  height: 400px;
  background: url("../..//img/top/concept-bg.svg") center/contain no-repeat;
}

.concept .concept-headline {
  margin-bottom: 16px;
  font-size: 2.8rem;
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
}

.concept .concept-headline em {
  color: #c80952;
  font-size: 3.2rem;
}
